@use "../../../../css/mixins" as mixins;
@use "../../../../css/variables" as variables;
@use "../../../../css/responsives" as responsives;
@use "../../../../css/colors" as colors;

.projectsCount {
  @include mixins.flex_container(column, center, flex-end);

  @include responsives.tablet {
    @include mixins.flex_container(row, flex-start, center);
  }

  p {
    color: colors.$color-greenlight;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 2.049rem;

    @include responsives.large {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @include responsives.tablet {
      align-self: flex-end;
      line-height: 2.9rem;
    }
  }

  .number {
    font-weight: 500;
    font-size: 6rem;
    line-height: 5.18rem;

    @include responsives.large {
      font-size: 4.62rem;
      line-height: 6.3rem;
    }

    @include responsives.tablet {
      font-size: 3.43rem;

      line-height: 4.3rem;
      margin-right: 1rem;
    }
  }
}
