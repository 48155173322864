@use "../../css/colors" as colors;
@use "../../css/responsives" as responsives;

.card {
  display: flex;
  height: 26.6rem;
  background-color: colors.$color-white;
  width: 22.65rem;

  @include responsives.large {
    width: 14rem;
    height: 27.5rem;
    font-size: 0.93rem;
  }

  @include responsives.tablet {
    width: 20rem;
    height: 19.37rem;
  }
}

.card:not(:last-child) {
  @include responsives.large {
    margin-right: 1.3rem;
  }

  @include responsives.tablet {
    margin-right: 0;
    margin-bottom: 1.43rem;
  }
}

.cardContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.75rem;
  margin-right: 3.1rem;
  margin-bottom: 1.37rem;
  margin-left: 1.87rem;

  @include responsives.large {
    margin-top: 1.75rem;
    margin-right: 2.5rem;
    margin-bottom: 1.68rem;
    margin-left: 1.31rem;
  }

  @include responsives.tablet {
    margin-top: 1.37rem;
    margin-right: 2.18rem;
    margin-bottom: 2.56rem;
    margin-left: 0.93rem;
  }
}

.cardTitle {
  font-size: 2.5rem;
  font-weight: 200;
  max-height: 5.3rem;
  max-width: 12.5rem;
  margin-bottom: 5%;

  @include responsives.large {
    font-size: 1.9rem;
    max-width: 10rem;
  }

  @include responsives.tablet {
    font-size: 1.37rem;
    max-width: 8rem;
    margin-bottom: 2.56rem;
  }
}

.cardContent {
  line-height: 1.5rem;

  @include responsives.tablet {
    font-size: 0.87rem;
  }
}
