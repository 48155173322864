@use "../../css/mixins" as mixins;
@use "../../css/colors" as colors;
@use "../../css/variables" as variables;
@use "../../css/responsives" as responsives;

.companySign {
  text-align: right;
  color: colors.$color-white;
  font-size: 1rem;

  @include responsives.tablet {
    padding-top: 1rem;
    font-size: 0.75rem;
    text-align: center;
  }

  img {
    width: 40%;
    vertical-align: middle;

    @include responsives.tablet {
      width: 30%;
    }
  }

  a {
    padding-left: 0.5rem;
  }
}
