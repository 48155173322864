@use "../../../../css/colors" as colors;
@use "../../../../css/mixins" as mixins;
@use "../../../../css/variables" as variables;
@use "../../../../css/responsives" as responsives;

.navBar {
  @include mixins.flex_container(row, flex-end, center);
  width: 100%;
  max-width: 62.5rem;

  @include responsives.large {
    display: none;
  }

  ul {
    @include mixins.flex_container(row, space-between, center);
    width: 75%;
    list-style: none;
    margin-bottom: 0;

    a {
      color: colors.$color-white;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .navBarLink {
    text-transform: uppercase;
  }
}

.navBarMobile {
  display: none;
  position: fixed;
  background-color: colors.$color-black;
  width: 100%;
  height: 100vh;
  padding-top: 6rem;

  @include responsives.large {
    @include mixins.flex_container(column, flex-start, flex-start);
  }

  ul {
    @include mixins.flex_container(column, center, flex-start);
    width: 100%;
    list-style: none;
    padding: 0 2rem;

    li {
      width: 100%;
      padding: 2rem 0;
      border-bottom: 0.025rem solid colors.$color-white;
    }

    a {
      color: colors.$color-white;
      font-size: 1.25rem;
      line-height: 1.688rem;
      font-weight: 200;
      text-transform: uppercase;
    }
  }
}

.navBarSocial {
  display: none;

  @include responsives.mobile {
    @include mixins.flex_container(column, center, center);
    width: 100%;
    padding: 1rem;
  }
}
