@use "../css/colors" as colors;
@use "../css/fonts" as fonts;

/// @param {string} $background-color
///   Background color for buttons.
/// @return {string} $color.
///   Font color

/// @param {string} $direction
///   flex-direction value
/// @param {string} $justify
///   justify-content value
/// @param {string} $align
///   align-items value
@mixin flex_container($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}
