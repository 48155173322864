@use "../../css/mixins" as mixins;
@use "../../css/responsives" as responsives;

.socialMedia {
  @include mixins.flex_container(row, space-between, flex-end);

  > a {
    img {
      width: 52px;
      height: 52px;
    }
  }

  > a:not(:first-of-type) {
    padding-left: 1.25rem;
  }
}

.socialMediaNavBar {
  padding: 1.5rem;
  text-align: center;

  > a {
    img {
      width: 32px;
      height: 32px;
    }
  }

  > a:not(:first-of-type) {
    padding-left: 1.25rem;
  }
}
