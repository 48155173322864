@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;
@use "../../css/responsives" as responsives;

.container {
  @include mixins.flex_container(row, center, center);

  @include responsives.large {
    display: none;
  }

  .header {
    @include mixins.flex_container(row, space-between, center);
    width: 100%;
    max-width: 1920px;
    padding: 0 4.12rem;
    padding-top: 1.75rem;

    > div {
      @include mixins.flex_container(row, flex-end, center);
      width: 100%;
    }
  }
}

.headerImage {
  height: 44px;
}

.containerMobile {
  display: none;
  width: 100%;
  max-width: 100%;

  @include responsives.large {
    @include mixins.flex_container(column, flex-start, flex-start);
  }

  .headerMobile {
    @include mixins.flex_container(row, space-between, center);
    width: 100%;
    z-index: 999;
    padding: 1rem 2.3rem 0 2.3rem;

    a > img {
      height: 44px;
    }

    @include responsives.mobileSmall {
      padding: 0.8rem 0.8rem 0 0.8rem;
    }
  }

  .menuIcon {
    @include responsives.large {
      display: block;
      background-color: colors.$color-transparent;
    }

    display: none;
    text-align: right;
    border: none;
    cursor: pointer;
    z-index: 9999;

    img {
      width: 30px;
      height: 25px;
    }
  }
}
