@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;
@use "../../css/fonts" as fonts;
@use "../../css/responsives" as responsives;

.container {
  width: variables.$web-body-width;

  @include responsives.large {
    @include mixins.flex_container(row, flex-start, flex-start);
    padding-left: 6.9rem;
    width: 100%;
  }

  @include responsives.tablet {
    width: 20rem;
    padding-left: 0;
  }

  h2 {
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-align: start;
    font-weight: 200;
    text-transform: uppercase;
    font-size: 1.25rem;
    line-height: 1.688rem;

    @include responsives.tablet {
      padding: variables.$web-padding-mobile 0;
    }
  }
}

.whiteColor {
  color: colors.$color-white;
}
