@use "../../css/mixins" as mixins;
@use "../../css/colors" as colors;
@use "../../css/responsives" as responsives;

.container {
  background-image: url("../../assets/images/Home/background.jpg"); /* fallback */
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    url("../../assets/images/Home/background.jpg");
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-bottom: 6rem;
}

.principalSection {
  width: 48rem;
  height: auto;
  color: colors.$color-white;
  margin-left: 7.5rem;
  margin-top: 20%;

  @include responsives.extraLargeLarge {
    width: auto;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 7.5rem;
  }

  @include responsives.extraLarge {
    width: auto;
    margin-left: 13.5rem;
    padding-left: 0;
  }

  @include responsives.large {
    width: auto;
    margin-top: 25%;
    margin-left: 6.5rem;
    padding-left: 0;
  }

  @include responsives.mobile {
    width: auto;
    margin-left: 0;
    margin-top: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include responsives.mobileSmall {
    margin-top: 60%;
  }
}

.titleMobile {
  @include responsives.mobile {
    max-width: 21rem;
  }
}

.subTitleMobile {
  @include responsives.mobile {
    padding-right: 4rem;
  }
}

.arrowContainer {
  display: none;
  @include responsives.mobile {
    margin-top: 3.2rem;
    display: flex;
    justify-content: center;
  }
}
