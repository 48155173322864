@use "../../../../css/mixins" as mixins;
@use "../../../../css/colors" as colors;
@use "../../../../css/responsives" as responsives;

.paragraph {
  @include mixins.flex_container(column, center, flex-start);
  color: white;
  width: 37rem;
  height: 13.875rem;

  @include responsives.desktop {
    margin: 2rem 0;
  }

  @include responsives.tablet {
    width: 20rem;
    height: auto;
  }

  h1 {
    font-weight: 700;
    font-size: 3.125rem;
    line-height: 100%;
    margin-bottom: 1rem;

    @include responsives.tablet {
      font-size: 2.25rem;
    }
  }

  h4 {
    font-weight: 100;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 4rem;

    @include responsives.desktop {
      margin-bottom: 2rem;
    }

    @include responsives.tablet {
      width: 15rem;
    }
  }

  a {
    color: colors.$color-green;
  }

  .greenLine {
    @include mixins.flex_container(row, center, center);
    width: 4.25rem;
    height: 0.1875rem;
    background: colors.$color-green;
  }
}
