$web-padding: 3rem;
$web-padding-left: 7.8rem;
$web-padding-mobile: 2rem;
$web-width: 90rem;
$web-max-width: 120rem;
$web-body-width: 74rem;

$height-footer: 18.563rem;
$height-header: 6.25rem;
$min-height-main: calc(100vh - #{$height-footer} - #{$height-header});
