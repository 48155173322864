@use "../../css/mixins" as mixins;
@use "../../css/colors" as colors;
@use "../../css/variables" as variables;
@use "../../css/responsives" as responsives;

.partner {
  @include mixins.flex_container(column, space-between, center);
  background-color: colors.$color-white;
  width: 100vw;
  max-width: 100%;
  padding-bottom: 2rem;

  .partnerImages {
    @include mixins.flex_container(row, center, center);
    flex-wrap: wrap;

    @include responsives.extraLargeLarge {
      max-width: variables.$web-width;
    }
  }
}
