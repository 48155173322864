@use "../../css/responsives" as responsives;

.subtitle {
   margin-top: 2rem;
   line-height: 1.5rem;

   @include responsives.extraLargeLarge {
      max-width: 20.9rem;
   }

   @include responsives.mobile {
      margin-top: 3.5rem;
   }
}
