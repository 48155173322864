@use "./css/colors" as colors;
@use "./css/fonts" as fonts;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: fonts.$font-manrope;
  font-style: normal;
}

a {
  text-decoration: none !important;
}

body,
html {
  width: 100%;
  height: 100%;
  background-color: colors.$color-black;
  margin: 0;
}
