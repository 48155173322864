@use "../../../src/css/mixins" as mixins;
@use "../../../src/css/responsives" as responsives;
@use "../../../src/css/variables" as variables;

.contact {
  @include mixins.flex_container(row, center, center);
  background-image: url("../../assets/images/Home/background.jpg"); /* fallback */
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    url("../../assets/images/Home/background.jpg");
  background-size: cover;
  height: 47.625rem;
  width: 100vw;
  max-width: 100%;

  @include responsives.large {
    width: 100%;
  }

  @include responsives.desktop {
    height: auto;
  }
}

.contactBody {
  @include mixins.flex_container(row, space-between, center);
  width: variables.$web-body-width;

  @include responsives.large {
    width: 100%;
    padding: 0 3rem;
    gap: 0;
  }

  @include responsives.desktop {
    @include mixins.flex_container(column, center, center);
  }
}
