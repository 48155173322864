@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;
@use "../../css/responsives" as responsives;

.footer {
  @include mixins.flex_container(row, center, center);
  height: variables.$height-footer;
  font-size: 1rem;
  color: colors.$color-white;

  @include responsives.large {
    font-size: 0.75rem;
  }

  .footerBody {
    @include mixins.flex_container(column, center, center);

    @include responsives.extraLargeLarge {
      width: variables.$web-width;
    }

    @include responsives.extraLarge {
      width: 76rem;
    }

    @include responsives.large {
      width: 53rem;
    }

    @include responsives.desktop {
      width: 100%;
    }
  }

  .footerLine {
    @include mixins.flex_container(row, space-between, center);
    width: 100%;

    @include responsives.tablet {
      @include mixins.flex_container(column, center, center);

      .footerLogo {
        height: 32px;
        margin-bottom: 2rem;
      }
    }

    a {
      color: #ffffff;
    }
    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
