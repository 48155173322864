@use "../../../../css/mixins" as mixins;
@use "../../../../css/responsives" as responsives;
@use "../../../../css/colors" as colors;

.form {
  @include mixins.flex_container(column, center, center);
  background-color: white;
  width: 31.25rem;
  height: 37rem;

  @include responsives.desktop {
    height: 34.5rem;
  }

  @include responsives.tablet {
    width: 20rem;
  }

  form {
    width: 85%;
    font-weight: 200;
    font-size: 1rem;
    line-height: 150%;

    input,
    textarea {
      width: 100%;
      margin-bottom: 1.125rem;
      border: none;
      background-color: #f2f2f2;
      padding-left: 0.5rem;
    }

    p {
      font-weight: 500;
      margin-bottom: 0.2rem;
    }

    input {
      height: 3.125rem;
    }

    textarea {
      resize: vertical;
    }

    div {
      @include mixins.flex_container(row, center, center);

      button {
        background-color: black;
        width: 11.25rem;
        height: 3.125rem;
        border: none;
        cursor: pointer;
        color: white;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.105em;
        text-transform: uppercase;
      }
    }
  }
}

.sent {
  padding-top: 7.125rem;
  padding-bottom: 10rem;
  text-align: center;

  img {
    width: 6rem;
    margin: 0 auto;
  }

  h2 {
    margin-top: 2.375rem;
    margin-bottom: 1.875rem;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  p {
    font-size: 0.875rem;
  }

  button {
    text-decoration: underline;
    font-weight: 700;
    border: 0;
    background: transparent;
  }
}
