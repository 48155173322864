@use "../../css/mixins" as mixins;
@use "../../css/colors" as colors;
@use "../../css/responsives" as responsives;
@use "../../css/variables" as variables;

.container {
  @include mixins.flex_container(column, center, center);
  background-color: colors.$color-black;
  width: 100%;
  max-width: 100%;
  margin-bottom: 5.43rem;

  @include responsives.extraLargeLarge {
    max-width: variables.$web-width;
  }

  @include responsives.desktop {
    @include mixins.flex_container(column, center, center);
  }
}

.cardsContainer {
  @include mixins.flex_container(row, space-between, flex-start);
  width: variables.$web-body-width;

  @include responsives.large {
    @include mixins.flex_container(row, flex-start, flex-start);
    padding-left: 6.9rem;
    width: 100%;
  }

  @include responsives.tablet {
    @include mixins.flex_container(column, center, center);
    padding-left: 0;
  }
}
