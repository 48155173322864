@use "../../../../css/mixins" as mixins;
@use "../../../../css/variables" as variables;
@use "../../../../css/responsives" as responsives;
@use "../../../../css/colors" as colors;

.metaverseTypes {
  @include mixins.flex_container(row, center, center);
  flex-flow: wrap;
  width: 75%;

  @include responsives.extraLargeLarge {
    max-width: variables.$web-width;
    justify-content: space-around;
    .metaverselogos:nth-last-child(2) {
      img {
        margin-left: 50%;
      }
    }
    .metaverselogos:nth-last-child(1) {
      img {
        margin-right: 50%;
      }
    }
  }

  @include responsives.tablet {
    .metaverselogos:nth-last-child(2) {
      img {
        margin-left: 0;
      }
    }
    .metaverselogos:nth-last-child(1) {
      img {
        margin-right: 0;
      }
    }
  }

  @include responsives.tablet {
    width: 100%;
  }
}

.metaverselogos {
  height: 80px !important;
}
