@use "../../css/responsives" as responsives;

.buttonsContainer {
  display: flex;
  margin-top: 46px;
}

.buttonMargin {
  margin-right: 2.75rem;

  @include responsives.mobile {
    margin-right: 2.1rem;
 }
}
