@use "../../css/colors" as colors;
@use "../../css/responsives" as responsives;

.paragraph {
  font-weight: 300;
}

.paragraphAboutPrimary {
  font-size: 2rem;
  line-height: 2.75rem;
  max-width: 570px;

  @include responsives.large {
    font-size: 1.4rem;
    line-height: 1.622rem;
  }

  @include responsives.tablet {
    font-size: 1.18rem;
    line-height: 1.84rem;
  }
}

.paragraphAboutSecondary {
  font-size: 1.25rem;
  line-height: 1.688rem;
  max-width: 32.5rem;

  @include responsives.large {
    font-size: 1rem;
    line-height: 1.366rem;
  }

  @include responsives.large {
    max-width: 18.75rem;
  }
}

.paragraphMetaversePrimary {
  color: colors.$color-grey;
  font-weight: 500;
  font-size: 1.625rem;
  line-height: 2.22rem;

  @include responsives.large {
    font-size: 1.25rem;
    line-height: 1.68rem;
  }

  @include responsives.tablet {
    font-size: 0.87rem;
    line-height: 1.19rem;
    max-width: 100%;
  }
}

.paragraphMetaverseSecondary {
  color: colors.$color-grey;
  font-size: 1.5rem;
  line-height: 2.049rem;

  @include responsives.large {
    font-size: 1.25rem;
    line-height: 1.68rem;
  }

  @include responsives.tablet {
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 700;
    padding-left: 0;
  }
}
