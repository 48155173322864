@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;

.entryLoader {
  position: relative;

  .loader {
    @include mixins.flex_container(row, center, center);
    position: fixed;
    transition: all 1s linear;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: colors.$color-black;
    z-index: 1000;

    img {
      width: 15%;
    }
  }

  .show {
    visibility: visible;
    opacity: 1;
  }

  .hide {
    visibility: hidden;
    opacity: 0;
  }
}
