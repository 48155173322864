@mixin mobileSmall {
  @media only screen and (max-width: 375px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin large {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin extraLarge {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}

@mixin extraLargeLarge {
  @media only screen and (max-width: 7680px) {
    @content;
  }
}
