@use "../../css/mixins" as mixins;
@use "../../css/colors" as colors;
@use "../../css/variables" as variables;
@use "../../css/responsives" as responsives;

.container {
  background-image: url("../../assets/images/Error404/background.png"); /* fallback */
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    url("../../assets/images/Error404/background.png");
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
}

.principalSection {
  width: 48rem;
  height: auto;
  color: colors.$color-white;
  margin-left: variables.$web-padding-left;
  margin-top: 20%;

  @include responsives.extraLargeLarge {
    margin-left: 13.5rem;
  }

  @include responsives.large {
    margin-left: 6.5rem;
    margin-top: 25%;
  }

  @include responsives.mobile {
    width: auto;
    margin-left: 2rem;
    margin-top: 25%;
  }
}
