@use "../../css/colors" as colors;
@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;
@use "../../css/responsives" as responsives;

.backToTop {
  p {
    background-color: colors.$color-black;
    border: 2px solid colors.$color-white;
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: colors.$color-white;

    padding: 1rem 0.3rem 1rem 0.2rem;
    font-weight: 300;
    text-align: center;
    transition: visibility 0.2s ease-out;
    visibility: hidden;
    width: 2rem;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    cursor: pointer;
  }

  .show {
    visibility: visible;
  }

  @include responsives.large {
    display: none;
  }
}
