@use "../../css/responsives" as responsives;

.title {
   font-weight: 200;
   font-size: 4.8rem;
   line-height: 4.5rem;

   @include responsives.extraLargeLarge {
      max-width: 44rem;
   }

   @include responsives.extraLarge {
      font-size: 4.3rem;
   }

   @include responsives.large {
      font-size: 4.3rem;
   }

   @include responsives.tablet {
      font-size: 41px;
      line-height: 41px;
      max-width: 21rem;
   }

   @include responsives.mobile {
      font-size: 2.25rem;
      line-height: 2.25rem;
      margin: 0;
   }
}
