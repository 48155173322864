@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;
@use "../../css/responsives" as responsives;
@use "../../css/colors" as colors;

.about {
  @include mixins.flex_container(column, center, center);
  background-color: colors.$color-grey;
  width: 100vw;
  max-width: 100%;
  padding-bottom: 5.31rem;

  @include responsives.tablet {
    padding-bottom: 3.75rem;
  }

  .aboutBody {
    @include mixins.flex_container(row, space-between, center);
    width: variables.$web-body-width;

    img {
      @include responsives.extraLargeLarge {
        width: 526px;
        height: 354px;
      }

      @include responsives.extraLarge {
        width: 526px;
        height: 354px;
        margin-right: 1rem;
      }
    }

    @include responsives.large {
      padding-right: 0;
      width: 100%;
      margin-bottom: 1rem;
      padding-left: 6.9rem;
      gap: 2rem;

      img {
        width: 370px;
        height: 280px;
      }
    }

    @include responsives.tablet {
      @include mixins.flex_container(column, center, center);
      grid-gap: 2rem;
      gap: 2rem;
      width: 20rem;
      padding: 0;

      img {
        width: 18rem;
        height: 14rem;
      }
    }
  }

  .aboutParagraph {
    @include mixins.flex_container(column, center, flex-start);
    gap: 2.75rem;

    @include responsives.large {
      max-width: 25.5rem;
      gap: 3rem;
    }

    @include responsives.tablet {
      max-width: 100%;
      grid-gap: 3rem;
      gap: 3rem;
    }
  }
}
