@use "../../css/mixins" as mixins;
@use "../../css/responsives" as responsives;

.boxImage {
  @include mixins.flex_container(row, center, center);
  height: 120px;
  width: 193px;

  @include responsives.large {
    @include mixins.flex_container(column, center, center);
    width: 180px;
    height: 90px;
  }

  @include responsives.tablet {
    width: 150px;
    height: 62px;
  }
}
