@use "../../css/mixins" as mixins;
@use "../../css/variables" as variables;
@use "../../css/responsives" as responsives;
@use "../../css/colors" as colors;

.metaverse {
  @include mixins.flex_container(column, center, center);
  background-image: url("../../assets/images/Metaverses/background.png");
  background-position: calc(45% - 5%) 70%;
  width: 100vw;
  max-width: 100%;
  padding-bottom: 1rem;

  @include responsives.extraLarge {
    background-position: 45% 70%;
  }

  .metaverseBody {
    @include mixins.flex_container(column, center, flex-start);
    width: variables.$web-body-width;

    @include responsives.large {
      @include mixins.flex_container(column, center, flex-start);
      padding-left: 6.9rem;
      width: 100%;
    }

    @include responsives.tablet {
      align-items: center;
      padding-left: 0;
      max-width: 20.43rem;
      font-weight: 500;
    }
  }

  .metaverseDescription {
    @include mixins.flex_container(row, center, center);
    gap: 4rem;

    padding-left: 4rem;
    padding-right: 20rem;
    padding-bottom: 2rem;

    @include responsives.large {
      padding-right: 13rem;
    }

    @include responsives.tablet {
      @include mixins.flex_container(column, center, flex-start);
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 1rem;
      gap: 2rem;
    }
  }

  .metaverseTypes {
    @include mixins.flex_container(column, center, center);
    gap: 1rem;
  }
}
