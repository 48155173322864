@use "../../css/colors" as colors;
@use "../../css/responsives" as responsives;

.button {
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   text-transform: uppercase;
   font-size: 1em;
   width: 12.5rem;
   height: 3rem;
   font-weight: 700;

   @include responsives.large {
      font-size: 0.93rem;
      width: 11.2rem;
      height: 2.81rem;
   }

   @include responsives.mobile {
      font-size: 0.8rem;
      width: 9.6rem;
      height: 2.4rem;
   }
}

.borderedWhite {
   color: colors.$color-white;
   border: 2px solid #F8F0DF;
   background-color: colors.$color-black;
   &:hover {
      border-color: colors.$color-green;
      color: colors.$color-black;
      background-color: colors.$color-green;
   }
}

.filledBlack {
   width: 17rem;
   height: 5rem;
   color: colors.$color-green;
   background-color: colors.$color-black;
   &:hover {
      border-color: colors.$color-black;
      color: colors.$color-black;
      background-color: colors.$color-white;
   }

   @include responsives.tablet {
      font-size: 1rem;
      width: 12.9rem;
      height: 3.75;
    }

   @include responsives.mobile {
      font-size: 0.93rem;
      height: 2.75rem;
      width: 10.25rem;
   }
}
